import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import COLORS from 'constants/colors';
import TransitionLink from 'gatsby-plugin-transition-link';
import { TimelineMax, Power1 } from 'gsap';

class TLink extends Component {
  constructor(props) {
    super(props);

    this.cover = React.createRef();
  }

  animation = ({ node, props: { length: seconds }, closeMenu }) => {
    const body = document.body;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const pause = seconds / 4;
    const transitionOpacity = (seconds - pause) / 2;

    let vw = window.innerWidth;
    let vh = window.innerHeight;

    let i = Math.sqrt(Math.pow(vw, 2) + Math.pow(vh, 2));
    let h = Math.sqrt((Math.pow(vw, 2) * Math.pow(vh, 2)) / Math.pow(i, 2));
    let newDeltaY = Math.pow(h, 2) / vh;
    let newDeltaX = Math.sqrt(Math.pow(h, 2) - Math.pow(newDeltaY, 2));

    const canvasW = vw + newDeltaX * 2;
    const canvasH = vh + newDeltaY * 2;

    canvas.width = canvasW;
    canvas.height = canvasH;
    canvas.style.zIndex = 10000;
    canvas.style.top = `${-newDeltaY}px`;
    canvas.style.left = `${-newDeltaX}px`;
    canvas.style.position = 'fixed';

    body.appendChild(canvas);

    const triangle = {
      x: 0,
      y: 0,
    };

    function drawTriangle() {
      ctx.clearRect(0, 0, canvasW, canvasH);

      ctx.beginPath();
      ctx.moveTo(triangle.x, triangle.y);
      ctx.lineTo(triangle.x + vw, triangle.y);
      ctx.lineTo(triangle.x, triangle.y + vh);
      ctx.fillStyle = `rgba(${COLORS['darkGreenRGB']}, 1)`;
      ctx.fill();

      const triangle2X = canvasW - triangle.x;
      const triangle2Y = canvasH - triangle.y;

      ctx.beginPath();
      ctx.moveTo(triangle2X, triangle2Y);
      ctx.lineTo(triangle2X, triangle2Y - vh);
      ctx.lineTo(triangle2X - vw, triangle2Y);
      ctx.fillStyle = `rgba(${COLORS['blueRGB']}, 1)`;
      ctx.fill();
    }

    function removeCanvas(wait = 0) {
      setTimeout(() => {
        body.removeChild(canvas);
      }, wait);
    }

    function transitionStart(wait = 0) {
      requestAnimationFrame(() => {
        setTimeout(() => {
          closeMenu();
        }, wait);
      });
    }

    return new TimelineMax({
      onUpdate: drawTriangle,
      onStart: () => transitionStart(transitionOpacity * 1000),
      onComplete: () => removeCanvas(seconds / 3),
    })
      .set(node, { visibility: 'hidden' }, transitionOpacity)
      .to(
        triangle,
        transitionOpacity,
        {
          x: newDeltaX,
          y: newDeltaY,
          ease: Power1.ease,
        },
        0
      )
      .to(
        triangle,
        transitionOpacity,
        {
          x: 0,
          y: 0,
          ease: Power1.ease,
        },
        transitionOpacity + pause
      );
  };

  render() {
    return (
      <Consumer>
        {({ closeMenu }) => (
          <TransitionLink
            exit={{
              length: 1,
              trigger: ({ exit, node }) =>
                this.animation({ props: exit, node, closeMenu }),
            }}
            entry={{
              delay: 0.5,
              length: 1,
            }}
            {...this.props}
          >
            {this.props.children}
          </TransitionLink>
        )}
      </Consumer>
    );
  }
}

TLink.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TLink;
