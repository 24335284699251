import React from 'react';
import Section from 'components/section';
import { Container } from './header.css';
import HeaderMenu from 'containers/headermenu';
import TLink from 'components/transitionlink';
import Logo from 'images/logo-oltredanza-asd.svg';

const Header = () => (
  <Section as={Container} className="siteHeader">
    <TLink to="/" className="logo">
      <Logo style={{ width: '320px', height: 'auto' }} />
    </TLink>
    <HeaderMenu />
  </Section>
);

export default Header;
