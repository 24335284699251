import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './icon.css';
import ICONS from 'constants/icons';

const Icon = ({ type = '', color = 'darkGray', className }) => {
  if (type == '') return null;

  let SvgIcon = ICONS[type];

  return (
    <Wrapper className={className} style-color={color} aria-hidden="true">
      <SvgIcon />
    </Wrapper>
  );
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['darkGray']),
  className: PropTypes.string,
};

export default Icon;
