import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import COLORS from 'constants/colors';

export const Container = styled.footer`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const ColumnWrapper = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 2;
  padding-bottom: 25px;

  ${MEDIA.MIN_TABLET`
    width: 25%;
    padding-bottom: 0;
    padding-left: 10px;
  `};

  &:first-child {
    padding-left: 0;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    padding-bottom: 15px;
    margin-top: -8px;
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .listImage {
    display: flex;

    li {
      display: flex;
      flex-basis: 75px;
      flex-direction: column;
      margin-right: 15px;

      p {
        border-bottom: 1px solid ${COLORS.mediumGray};
        margin-bottom: 15px;
        padding-bottom: 15px;
      }
    }
  }
`;

export const NavWrapper = styled(ColumnWrapper)`
  line-height: 1.4;

  li {
    font-family: 'balboaplus-fill', sans-serif;
    font-size: 32px;
  }
`;
