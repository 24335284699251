import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import COLORS from 'constants/colors';

let MAX_WIDTH = 1133;

export const Container = styled.div`
  max-width: 100%;
  padding: 50px 20px;
  background-color: ${({ styleColor }) => COLORS[styleColor] || 'transparent'};
  margin: ${({ styleColor }) => (styleColor ? '25px 0' : '0')};

  ${MEDIA.MIN_TABLET`
    padding-left: 40px;
    padding-right: 40px;
  `};

  ${MEDIA.MIN_LARGE_DESKTOP`
    padding-left: calc(50% - ${MAX_WIDTH / 2}px);
    padding-right: calc(50% - ${MAX_WIDTH / 2}px);
  `};
`;
