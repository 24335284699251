import styled from 'styled-components';
import COLORS from 'constants/colors';

export const Wrapper = styled.span`
  display: block;

  svg {
    display: block;
  }

  path {
    fill: ${({ styleColor }) => COLORS[styleColor]};
  }
`;
