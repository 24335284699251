import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { Container } from './section.css';

const Section = ({ children, as, className, bgColor, bgPattern = false }) => (
  <Container
    as={as}
    className={className}
    styleColor={bgColor}
    bgPattern={bgPattern}
  >
    {children}
  </Container>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.oneOf(['green', 'darkGreen', 'blue']),
  bgPattern: bool,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
};

export default Section;
