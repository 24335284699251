import styled from 'styled-components';
import COLORS from 'constants/colors';

export const Container = styled.header`
  position: relative;
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
  justify-content: space-between;
  align-items: center;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    display: block;
    border-bottom: 2px dotted ${COLORS.grayLine};
    width: 100%;
  }

  .logo {
    display: block;
    width: 70%;
    position: relative;
    z-index: 12;
    padding: 20px 0;

    svg {
      width: 100%;
      max-width: 100%;
      display: block;

      #icon-2 {
        > path {
          mix-blend-mode: multiply;
          opacity: 0;

          &:nth-child(12n),
          &:nth-child(12n + 3),
          &:nth-child(12n + 6),
          &:nth-child(12n + 9) {
            transition: opacity 666ms ease;
            will-change: opacity;
          }

          &:nth-child(12n),
          &:nth-child(12n + 6) {
            transition-delay: 333ms;
          }

          &:nth-child(12n + 3) {
            transition-delay: 999ms;
          }
        }
      }
    }

    &:hover,
    &:focus {
      #icon-2 > path {
        &:nth-child(12n),
        &:nth-child(12n + 6) {
          opacity: 0.5;
        }

        &:nth-child(12n + 9),
        &:nth-child(12n + 3) {
          opacity: 0.75;
        }
      }
    }
  }
`;
