import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Section from 'components/section';
import { Container, NavWrapper, ColumnWrapper } from './footer.css';
import Nav from 'components/nav';
import { Link } from 'gatsby';
import TLink from 'components/transitionlink';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        coniImage: file(relativePath: { eq: "coni.png" }) {
          childImageSharp {
            fixed(height: 42) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        csenImage: file(relativePath: { eq: "csen.png" }) {
          childImageSharp {
            fixed(height: 42) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <Section as={Container}>
        <NavWrapper as="nav">
          <Nav />
        </NavWrapper>

        <ColumnWrapper>
          <h3>Indirizzo</h3>
          <p>
            Oltredanza A.S.D. <br />
            Via Uberto Bonino, 1/G <br />
            98124 Messina (ME)
          </p>
        </ColumnWrapper>
        <ColumnWrapper>
          <h3>Contatti</h3>
          <p>
            M. oltredanzamessina@gmail.com <br />
            T. +39 393/9137123 <br />
            C.F. 97121260836
          </p>
        </ColumnWrapper>
        <ColumnWrapper>
          <h3>Associazione</h3>
          <ul>
            <li>
              <a href="/statuto-oltredanza.pdf" target="_blank">
                Statuto
              </a>
            </li>
            <li>
              <TLink to="/regolamento">Regolamento</TLink>
            </li>
            <li>
              <Link to="/privacy">Privacy</Link>
            </li>
          </ul>
        </ColumnWrapper>
        <ColumnWrapper>
          <ul className="listImage">
            <li aria-label="Affiliati Coni">
              <p>Affiliati</p>
              <Img fixed={data.coniImage.childImageSharp.fixed} />
            </li>
            <li aria-label="Iscritti Csen">
              <p>Iscritti</p>
              <Img fixed={data.csenImage.childImageSharp.fixed} />
            </li>
          </ul>
        </ColumnWrapper>

        <ColumnWrapper />
      </Section>
    )}
  />
);

export default Footer;
