import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import HeaderMenu from 'components/headermenu';

const HeaderMenuContainer = ({ children }) => (
  <Consumer>
    {({ menuIsOpen, openMenu, closeMenu }) => (
      <HeaderMenu isOpen={menuIsOpen} open={openMenu} close={closeMenu}>
        {children}
      </HeaderMenu>
    )}
  </Consumer>
);

HeaderMenuContainer.propTypes = {
  children: PropTypes.node,
};

export default HeaderMenuContainer;
