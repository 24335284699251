import SocialFacebook from 'images/social-fb.svg';
import SocialInstagram from 'images/social-instagram.svg';
import VBurger from 'images/vburger.svg';
import Photo from 'images/photo.svg';
import Download from 'images/download.svg';

export default {
  socialFb: SocialFacebook,
  socialIg: SocialInstagram,
  vBurger: VBurger,
  photo: Photo,
  download: Download,
};
