// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-associazione-js": () => import("./../src/pages/associazione.js" /* webpackChunkName: "component---src-pages-associazione-js" */),
  "component---src-pages-claudia-bertuccelli-js": () => import("./../src/pages/claudia-bertuccelli.js" /* webpackChunkName: "component---src-pages-claudia-bertuccelli-js" */),
  "component---src-pages-contatti-js": () => import("./../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-corsi-js": () => import("./../src/pages/corsi.js" /* webpackChunkName: "component---src-pages-corsi-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-regolamento-js": () => import("./../src/pages/regolamento.js" /* webpackChunkName: "component---src-pages-regolamento-js" */),
  "component---src-pages-scuola-js": () => import("./../src/pages/scuola.js" /* webpackChunkName: "component---src-pages-scuola-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-tilia-ruggeri-js": () => import("./../src/pages/tilia-ruggeri.js" /* webpackChunkName: "component---src-pages-tilia-ruggeri-js" */)
}

