import React from 'react';
import TLink from 'components/transitionlink';

const Nav = () => {
  return (
    <nav>
      <ul>
        <li>
          <TLink to="/corsi">Corsi</TLink>
        </li>
        <li>
          <TLink to="/scuola">Scuola</TLink>
        </li>
        <li>
          <TLink to="/team">Team</TLink>
        </li>
        <li>
          <TLink to="/associazione">Associazione</TLink>
        </li>
        <li>
          <TLink to="/contatti">Contatti</TLink>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
