export default {
  green: '#B1D78A',
  darkGreen: '#31C398',
  darkGreenRGB: '49, 195, 152',
  lightBlue: '#01A9E4',
  blue: '#153E76',
  blueRGB: '21, 62, 118',
  pink: '#F79186',
  ultradarkGray: '#5E5E5E',
  darkGray: '#444444',
  mediumGray: '#A5A5A5',
  grayLine: '#E7E7E7',
  gray: '#F1F1F1',
  lightGray: '#F7F7F7',
  black: '#000000',
  blackRGB: '0,0,0',
  white: '#ffffff',
};
