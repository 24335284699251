import styled from 'styled-components';
import COLORS from 'constants/colors';
import MEDIA from 'helpers/mediaTemplates';

const TRANSITION_TIMING = 333;

export const NavWrapper = styled.div`
  padding: 90px 20px 0;
  background: ${COLORS.blue};
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  z-index: 10;
  transition: transform 0ms, opacity 500ms;
  ${props => {
    if (props.isOpen) {
      return `
      transform: scale3d(1, 1, 1);
      opacity: 1;
      `;
    } else {
      return `
      transform: scale3d(0.001, 0.001, 1);
      opacity: 0;
      `;
    }
  }};

  ${MEDIA.MINX_SMARTPHONE_LARGE`
    padding-top: 100px;
  `};

  ${MEDIA.MIN_TABLET`
    padding-top: 150px;
    padding-left: 40px;
    padding-right: 40px;
  `};

  ${MEDIA.MIN_DESKTOP`
    padding: 0;
    transform: scale3d(1, 1, 1);
    opacity: 1;
    background: none;
    flex-direction: row;
    width: auto;
    height: auto;
    position: static;
    left: auto;
    top: auto;
  `};

  .pattern {
    position: absolute;
    z-index: -1;

    &:nth-of-type(1) {
      top: 160px;
      right: -50px;

      path {
        transition: transform 1111ms, opacity 666ms;
        ${props => {
          if (props.isOpen) {
            return `
            transform: translate3d(0, 0, 0);
            opacity: 1;
          `;
          } else {
            return `
              transform: translate3d(0, 50px, 0);
              opacity: 0;
            `;
          }
        }};
      }
    }

    &:nth-of-type(2) {
      left: 0;
      bottom: -10%;

      ${MEDIA.MIN_SMARTPHONE_LARGE`
        bottom: -10px;
      `};

      circle {
        ${props => {
          if (props.isOpen) {
            return `
              r: 6;
              opacity: 1;
              transition: r 999ms, opacity 333ms;
              transition-delay: 333ms;            
          `;
          } else {
            return `
              r: 0;
              opacity: 0;
              transition: r 0ms, opacity 0ms;
              transition-delay: 0;
            `;
          }
        }};
      }
    }

    ${MEDIA.MIN_DESKTOP`
      display: none;
    `};
  }

  nav,
  ul {
    ${MEDIA.DESKTOP`
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
    `};
  }

  ul {
    display: flex;
    flex-direction: column;

    ${MEDIA.MIN_DESKTOP`
      align-items: center;
      justify-content: center;
      flex-direction: row;
  `};
  }

  li {
    text-transform: uppercase;
    font-family: 'balboaplus-fill', sans-serif;
    font-size: 40px;
    line-height: 1.4;

    ${MEDIA.MINX_SMARTPHONE_LARGE`
      line-height: 1.6;
    `};

    ${MEDIA.MIN_SMARTPHONE_LARGE`
      font-size: 48px;
      line-height: 1.6;
    `};

    ${MEDIA.MIN_DESKTOP`
      font-size: 32px;
      padding: 0 15px;
      font-family: inherit;
      font-size: inherit;
    `};

    a {
      transition: color ${TRANSITION_TIMING}ms;
      will-change: color;

      &:not([aria-current='page']) {
        color: ${COLORS.white};

        ${MEDIA.MIN_DESKTOP`
          color: ${COLORS.darkGray};
        `};

        &:hover {
          color: ${COLORS.mediumGray};

          ${MEDIA.MIN_DESKTOP`
            color: ${COLORS.blue};
          `};
        }
      }
    }

    [aria-current='page'] {
      color: ${COLORS.darkGreen};
    }
  }
`;

export const SocialBtnWrapper = styled.nav`
  &,
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    ${MEDIA.MINX_SMARTPHONE_LARGE`
      margin-top: 40px;
    `};

    ${MEDIA.MIN_TABLET`
      margin-top: 60px;
    `};

    ${MEDIA.MIN_DESKTOP`
      margin: 0;
    `};
  }

  li {
    padding: 0 15px;
  }
`;

export const SocialBtn = styled.a`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: ${COLORS.gray};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background ${TRANSITION_TIMING}ms;
  will-change: background;

  ${MEDIA.MIN_DESKTOP`
    width: 30px;
    height: 30px;
    margin-left: 8px;
  `};

  &:first-child {
    ${MEDIA.TABLET`
      margin-right: 16px;
    `};
  }

  svg {
    ${MEDIA.TABLET`
      width: 24px;
      height: 24px;
    `};
  }

  svg,
  path {
    transition: fill ${TRANSITION_TIMING}ms;
    will-change: fill;
  }

  &:hover {
    background: ${COLORS.white};

    svg,
    path {
      fill: ${COLORS.blue};
    }
  }
`;

export const VburgerBtn = styled.button`
  width: 54px;
  height: 54px;
  border-radius: 50%;
  border: 0;
  background: ${COLORS.gray};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  position: relative;
  z-index: 11;

  ${MEDIA.MIN_DESKTOP`
    display: none;
  `};

  span {
    position: absolute;
    left: 0;
    top: 26px;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 2px;
      position: absolute;
      top: 0;
      left: 15px;
      border-radius: 2px;
      background-color: ${COLORS.blue};
      will-change: transform, opacity;
      transition: transform 222ms ease, opacity 333ms 111ms ease;
    }

    &:nth-child(1) {
      &::before {
        transform: translate3d(0, -8px, 0);
      }

      &::after {
        transform: translate3d(0, 8px, 0);
      }

      ${props => {
        if (props.isOpen) {
          return `
            opacity: 0;
          `;
        }
      }};
    }

    &:nth-child(2) {
      &:before {
        ${props => {
          if (props.isOpen) {
            return `
              transform: rotate(45deg);
            `;
          }
        }};
      }

      &:after {
        ${props => {
          if (props.isOpen) {
            return `
              transform: rotate(-45deg);
            `;
          }
        }};
      }
    }
  }
`;
