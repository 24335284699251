import React from 'react';
import PropTypes from 'prop-types';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';

import Header from 'components/header';
import Footer from 'components/footer';

import 'font-face.css';
import GlobalStyle from 'global.css.js';
import '@reach/skip-nav/styles.css';

const Layout = ({ children }) => (
  <div>
    <GlobalStyle />
    <SkipNavLink>Vai al contenuto principale</SkipNavLink>
    <Header />
    <SkipNavContent />
    {children}
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
