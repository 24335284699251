import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  NavWrapper,
  SocialBtnWrapper,
  SocialBtn,
  VburgerBtn,
} from './headermenu.css';
import Nav from 'components/nav';
import Icon from 'components/icon';
import VisuallyHidden from '@reach/visually-hidden';
import COLORS from 'constants/colors';
import PatternCircual from 'images/pattern-circual.svg';
import PatternSquareBlu from 'images/pattern-square-blu.svg';

class HeaderMenu extends PureComponent {
  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = ({ key }) => {
    if (key === 'Escape') {
      this.props.isOpen && this.props.close();
    }
  };

  disableScrolling(open) {
    if (open) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = null;
      document.body.style.position = null;
      document.documentElement.style.overflow = null;
    }
  }

  render() {
    const { children, isOpen, open, close } = this.props;

    if (typeof document !== 'undefined') {
      this.disableScrolling(isOpen);
    }

    return (
      <>
        <VburgerBtn
          onClick={isOpen ? close : open}
          isOpen={isOpen}
          aria-label="Toggle menu"
        >
          <span />
          <span />
        </VburgerBtn>

        <NavWrapper isOpen={isOpen}>
          <Nav />

          <SocialBtnWrapper>
            <SocialBtn
              as="a"
              href="https://www.facebook.com/oltredanzame/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <VisuallyHidden>Facebook</VisuallyHidden>
              <Icon type="socialFb" />
            </SocialBtn>

            <SocialBtn
              as="a"
              href="https://www.instagram.com/oltredanza_official/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <VisuallyHidden>Instagram</VisuallyHidden>
              <Icon type="socialIg" />
            </SocialBtn>
          </SocialBtnWrapper>

          <PatternCircual fill={COLORS.lightBlue} className="pattern" />
          <PatternSquareBlu fill={COLORS.lightBlue} className="pattern" />
        </NavWrapper>

        {children}
      </>
    );
  }
}

HeaderMenu.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  open: PropTypes.func,
  close: PropTypes.func,
};

export default HeaderMenu;
