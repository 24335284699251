import { createGlobalStyle } from 'styled-components';
import COLORS from 'constants/colors';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: 'Chivo', Helvetica, Arial, sans-serif;
    line-height: 1.8;
    font-size: 18px;
    color: ${COLORS.ultradarkGray};
    background-color: ${COLORS.lightGray};
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }

  a {
    text-decoration: none;
  }

  b,
  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  a {
    &,
    &:visited,
    &:active {
      color: inherit;
    }

    &:focus,
    &:hover {
      color: ${COLORS.blue};
    }
  }


  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  video {
    max-width: 100%;
  }

  .body {
    ol,
    ul {
      list-style: inside;
    }

    li {
      margin: 25px 0;
    }

    p {
      margin: 8px 0;
    }

    b,
    strong {
      color: ${COLORS.blue};
    }

    h2, h3 {
      color: ${COLORS.blue};
      font-weight: bold;
    }

    h2 {
      &:not(:first-child) {
        margin-top: 25px;
      }
    }

    p + h3 {
      margin-top: 15px;
    }
  }

  :focus {
    outline: 0;
  }
`;
